
import { defineComponent } from 'vue';
import { useStore } from 'vuex';

import NavContainer from '@/components/navigation/NavContainer.vue';
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';

export default defineComponent({
  components: {
    NavContainer,
    Header,
    Footer,
  },
  setup() {
    const store = useStore();

    window.addEventListener('resize', () => {
      store.state.windowsize = window.innerWidth;
    });
    document.addEventListener('scroll', e => {
      const totop = document.getElementById('backToTop');
      if (!totop) return;
      if (document.documentElement.scrollTop > 300) {
        totop.style.opacity = '1';
      } else {
        totop.style.opacity = '0';
      }
    });
  },
});
