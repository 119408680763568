<style lang="scss">
.suche-box-outer {
  @media (max-width: 992px) {
    margin-top: 20px;
  }

  .suche-box {
    .input-suche {
      position: relative;
      display: block;
      max-width: 100%;
      width: 100%;
      @media (max-width: 1200px) {
        width: 98%;
        margin: 0 auto;
      }

      .delete-icon {
        display: inline-block;
        margin-top: 2px;
        position: absolute;
        z-index: 99;
        top: 50%;
        transform: translateY(-50%);
        right: 20px;
        left: auto;
        cursor: pointer;
        opacity: 0;
        &:hover {
          color: $red;
        }
      }

      &:before {
        content: '';
        display: block;
        width: 0%;
        position: absolute;
        height: 1px;
        background: $blue-darker;
        right: 50%;
        top: 5px;
        z-index: 1;
        -webkit-transition: all 500ms ease;
        -moz-transition: all 500ms ease;
        -ms-transition: all 500ms ease;
        -o-transition: all 500ms ease;
        transition: all 500ms ease;
      }
      &:after {
        content: '';
        display: block;
        width: 0%;
        position: absolute;
        height: 1px;
        background: $blue-darker;
        right: 50%;
        bottom: 0;
        z-index: 1;
        -webkit-transition: all 500ms ease;
        -moz-transition: all 500ms ease;
        -ms-transition: all 500ms ease;
        -o-transition: all 500ms ease;
        transition: all 500ms ease;
      }
      input {
        border: 1px solid #ccc;
        margin-top: 5px;
        padding: 0.6rem 1.5rem 0.7rem 1.5rem;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        position: relative;
        width: 100%;
        display: block;
        background: #f8f8f8;
        text-align: center;
        -webkit-transition: all 300ms ease;
        -moz-transition: all 300ms ease;
        -ms-transition: all 300ms ease;
        -o-transition: all 300ms ease;
        transition: all 300ms ease;
        @media (max-width: 992px) {
        }
        &:focus {
          outline: none;
          //border-left:2px solid $blue;;
          //border-top:1px solid $blue;
          //border-bottom:1px solid $blue;
        }
        @media (max-width: 1500px) {
          min-width: 200px;
        }
      }
      &:focus-within {
        .delete-icon {
          opacity: 1;
        }
        &:after,
        &:before {
          width: 100%;
          right: 0;
        }
        input {
          border-radius: 0;
          border-left: 1px solid $blue-darker;
          border-right: 1px solid $blue-darker;
        }
      }
    }

    .btn-suche {
      display: none;
      padding: 0.7rem 2rem;
      background: #ccc;
      border-top-right-radius: 25px;
      border-bottom-right-radius: 25px;

      position: relative;
      left: -2px;
      font-weight: 300;
      letter-spacing: 1px;
      -webkit-transition: all 500ms ease;
      -moz-transition: all 500ms ease;
      -ms-transition: all 500ms ease;
      -o-transition: all 500ms ease;
      transition: all 500ms ease;
      span {
        display: inline-block;
        position: relative;
        top: 3px;
        color: #fff;
      }
      &:hover {
        background: $blue-darker;
        cursor: pointer;
      }
    }
  }
}

.overlay-suche {
  position: absolute;
  min-width: 100%;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  top: 65px;
  left: 0%;
  z-index: 999;
  padding: 20px;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    li {
      padding: 0px;
      background: #f9f9f9;
      a {
        padding: 10px 15px;
        &:hover {
          padding-left: 10px;
        }
      }
    }
  }
}

.overlay-visibility-enter-active,
.overlay-visibility-leave-active {
  transition: all 200ms ease-in-out;
}

.overlay-visibility-enter-from,
.overlay-visibility-leave-to {
  opacity: 0;
}
</style>

<template>
  <div class="suche-box-outer ">
    <div class="row justify-content-center align-self-center">
      <div class="col-md-12 d-flex suche-box">
        <div class="input-suche">
          <input v-model="searchtext" type="text" placeholder="Suche nach..." />
          <i @click="clearSearch" class="material-icons delete-icon">delete</i>
        </div>
        <div class="btn-suche"><span>SUCHE</span></div>
        <transition name="overlay-visibility">
          <div class="overlay-suche" v-if="showOverlay">
            <div v-if="loading">
              <ul>
                <li>Suche...</li>
              </ul>
            </div>
            <ul v-else-if="searchresult.length > 0">
              <li v-for="page in searchresult" :key="page.id">
                <NavLink :to="{ name: page.routename }"> {{ page.displayname }}</NavLink>
              </li>
            </ul>
            <div v-else>
              Leider konnten keine Ergebnisse gefunden werden...
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Axios, { AxiosError, AxiosResponse } from 'axios';
import { computed, defineComponent, ref, watch, watchEffect } from 'vue';
import { useStore } from 'vuex';
import { PageItem } from '../types';
import { debounce } from 'throttle-debounce';
import NavLink from '@/components/navigation/NavLink.vue';

export default defineComponent({
  components: {
    NavLink,
  },
  setup() {
    const store = useStore();
    const search = computed(() => store.state.searchstate);
    const width = computed(() => store.state.windowsize);
    const searchtext = ref('');
    const loading = ref(false);
    const searchfield = ref(null);
    const searchresult = ref<Array<PageItem>>([]);
    const bounce = debounce(500, () => {
      store
        .dispatch('searchPage', searchtext.value)
        .then((response: AxiosResponse) => {
          searchresult.value = response.data.message;
          loading.value = false;
        })
        .catch((error: AxiosError) => {
          console.error(error);
          loading.value = false;
        });
    });

    watchEffect(() => {
      // if (width.value > 992) {
      //   store.state.searchstate = true;
      // } else {
      //   store.state.searchstate = false;
      // }
      if (searchtext.value != '') {
        loading.value = true;
        bounce();
      }
    });

    const clearSearch = () => {
      searchtext.value = '';
    };

    const showOverlay = computed(() => {
      return searchtext.value != '';
    });

    return {
      search,
      searchtext,
      searchresult,
      showOverlay,
      loading,
      clearSearch,
    };
  },
});
</script>
