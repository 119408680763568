
import { defineComponent } from 'vue';
import { useStore } from 'vuex';

export default defineComponent({
  setup(props) {
    const store = useStore();
    const navigateHook = (navigate: Function) => {
      store.state.menustate = false;
      Object.keys(store.state.dropdownstate).forEach(link => {
        store.state.dropdownstate[link] = false;
      });
      navigate();
    };

    return { props, navigateHook };
  },
});
