
import { computed, defineComponent, ref } from 'vue';
import { useStore } from 'vuex';
export default defineComponent({
  props: {
    title: { type: String, required: true },
    open: Boolean,
    titleclass: String,
  },
  setup(props) {
    const checkSessionStorage = () => {
      let storage;
      try {
        storage = window['sessionStorage'];
        const x = '__storage_test__';
        storage.setItem(x, x);
        storage.removeItem(x);
        return true;
      } catch (e) {
        return e && (e.code === 22 || e.code === 1014 || e.name === 'QuotaExceededError' || e.name === 'NS_ERROR_DOM_QUOTA_REACHED') && storage && storage.length !== 0;
      }
    };

    const store = useStore();
    const isOpen = ref(false);
    if (checkSessionStorage()) {
      if (sessionStorage.getItem(props.title) !== null) {
        isOpen.value = sessionStorage.getItem(props.title) === 'true';
      }
    }

    const toggle = () => {
      isOpen.value = !isOpen.value;
      if (checkSessionStorage()) {
        sessionStorage.setItem(props.title, isOpen.value ? 'true' : 'false');
      }
    };

    return {
      isOpen,
      toggle,
    };
  },
});
