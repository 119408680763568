<style lang="scss">
header {
  position: relative;
  padding-top: 10px;
  border-bottom: 1px solid #eee;
  background-color: #fff;
  height: 80px;
  @media (max-width: 1200px) {
    height: auto;
    padding-bottom: 15px;
    //background: #f9f9f9;
  }
  @media (max-width: 992px) {
    border-bottom: 1px solid #eee;
    padding-bottom: 5px;
  }
  .wrapper {
    max-width: $width-wrapper;
    margin: 0 auto;
    @media (max-width: 1700px) {
      max-width: 90%;
    }
  }
  [class*='col-'] {
    border: 0px solid red;
  }

  .logo-box {
    text-align: center;
    display: none;
    @media (max-width: 1200px) {
      display: block;
    }
    a {
      display: block;
    }
    .router-link-active {
      border-left: 0;
      &:after {
        display: none !important;
      }
    }
    img {
      max-width: 120px;
      display: none;
      @media (max-width: 1200px) {
        display: block;
        margin-left: 20px;
      }
    }
    .title {
      text-transform: uppercase;
      text-align: left;
      font-size: 90%;
    }
  }

  .btn-top-right {
    text-align: right;
    padding-right: 20px;
    //border:1px solid red;
    @media (max-width: 1200px) {
      margin-top: 5px;
      text-align: right;
      padding-right: 0px;
    }
    @media (max-width: 500px) {
      margin-top: 4px;
      padding-right: 25px;
    }
    .btn-top-header {
      margin-right: 10px;
      display: inline-block;
      padding: 12px 10px;

      line-height: 1;
      //border:1px solid red;
      //border:1px solid red;
      @media (max-width: 1050px) {
        margin-right: 0;
      }

      i {
        display: inline-block;
        height: 1px;
        line-height: 0.1;
        position: relative;
        top: 6px;
        margin-right: 5px;
        @media (max-width: 630px) {
          margin-right: 0px;
        }
      }
      span {
        display: inline-block;
        //border:1px solid blue;

        @media (max-width: 1280px) {
          display: none !important;
        }
        @media (max-width: 630px) {
          //border:1px solid blue;
          display: none !important;
        }
      }
      &:hover {
        opacity: 0.8;
      }
    }
    .router-link-active {
      border-left: 0 !important;
      &:after {
        display: none;
      }
    }
    .btn-navi-sitebar {
      display: none;
      @media (max-width: 1200px) {
        display: inline-block;
        margin-right: 20px;
      }
      @media (max-width: 500px) {
        margin-right: 0;
      }
    }
    .siteBarOpen {
      opacity: 0.2;
    }
  }

  .nav-button {
    background: none;
    border: none;
    text-align: center;

    &:hover {
      background: none;
    }

    span {
      line-height: 1px;
      height: 1px;
      position: relative;
      top: 6px;
      padding: 10px 0px;
      font-size: 30px;
    }
  }

  .kommunikations-box {
    height: 55px;
    padding-left: 35px;

    @media (max-width: 1200px) {
      margin-top: 5px;
      padding-left: 15px;
    }
    position: relative;

    @media (max-width: 992px) {
      margin-top: 20px;
    }
    .kommunkations-txt {
      display: block;
      text-align: left;
      position: relative;
      @media (max-width: $width-xl) {
        text-align: center;
      }
      .txt-netzwerk {
        color: #666;
        display: block;
        padding-top: 15px;
        font-size: 110%;
        text-transform: uppercase;
        letter-spacing: 1px;
        @media (max-width: $width-lg) {
        }

        @media (max-width: 400px) {
          padding-top: 0;
          position: relative;
          top: -2px;
        }
      }
    }
    .suche-box-outer {
      @media (max-width: 1200px) {
        margin-top: 3px;
      }
      @media (max-width: 992px) {
        width: 94%;
        margin: 0 auto;
      }
    }
  }
  .searchbar-visibility-enter-active {
    transition: all 200ms ease;
  }
  .searchbar-visibility-leave-active {
    opacity: 0;
  }
  .searchbar-visibility-enter-from,
  .searchbar-visibility-leave-to {
    opacity: 0;
  }

  .navi-box {
    .navi-toggler {
      display: flex;
      align-items: center;
      padding: 10px;
      font-size: 120%;
      i {
        position: relative;
        top: 0px;
      }
      span {
        display: inline-block;
        margin-left: 5px;
      }
    }
  }
}
</style>
<template>
  <header>
    <div class="row">
      <div class="col-lg-4 col-4 logo-box">
        <router-link to="/"><img src="@/assets/img/generel/klz-logo.svg" alt="Kleine Zeitung"/></router-link>
      </div>

      <div class="col-xl-7 col-lg-12 col-12 order-xl-2 order-3 align-self-center kommunikations-box">
        <div class="kommunkations-txt">
          <transition name="searchbar-visibility">
            <span v-if="!showSearch" class="txt-netzwerk">Lösungen für Ihren Kommunikationserfolg</span>
          </transition>

          <transition name="searchbar-visibility">
            <Search v-if="showSearch"></Search>
          </transition>
        </div>
      </div>

      <div class="col-xl-5 col-lg-8 col-8 order-xl-3 order-2 justify-content-center align-self-center btn-top-right">
        <a href="#" @click="toggleSearch" class="btn-top-header"><i class="material-icons">search</i> <span>Suche</span></a>
        <!--<DropDownButton link="serviceheader" class="btn-top-header"> <span class="material-icons">settings</span>Service </DropDownButton>
        <DropDown link="serviceheader">
          <router-link :to="{ name: 'Calculator' }" class="dropdown-item">
            <span>Tarif <strong>Rechner</strong></span>
          </router-link>
          <router-link :to="{ name: 'Spezifikationen' }" class="dropdown-item">
            <span>Sepzifikationen</span>
          </router-link>
        </DropDown>-->
        <router-link :to="{ name: 'UnserTeam' }" class="btn-top-header"><i class="material-icons">supervisor_account</i><span>Unser Team</span></router-link>
        <router-link :to="{ name: 'KontaktFormular' }" class="btn-top-header"><i class="material-icons">mail_outline</i><span>Anfrage</span></router-link>

        <a href="#" class="btn-navi-sitebar btn-top-header" @click="toggleMenu" :class="{ siteBarOpen: menu }"><i class="material-icons">menu_open</i>Menu</a>
      </div>
    </div>
  </header>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';
import { useStore } from 'vuex';
//import NavLink from '@/components/navigation/NavLink.vue';
import Search from '@/components/Search.vue';
//import DropDown from '@/components/util/DropDown.vue';

export default defineComponent({
  components: {
    //NavLink,
    Search,
    //DropDown,
  },
  setup() {
    const store = useStore();
    const width = computed(() => store.state.windowsize);
    const showLogo = computed(() => width.value < 1080);
    const showSearch = computed(() => store.state.searchstate);

    const menu = computed(() => store.state.menustate);

    const toggleMenu = () => {
      store.state.menustate = !store.state.menustate;
    };

    const toggleSearch = () => {
      store.state.searchstate = !store.state.searchstate;
    };

    return {
      menu,
      toggleMenu,
      toggleSearch,
      showSearch,
      showLogo,
    };
  },
});
</script>
