
import Axios, { AxiosError, AxiosResponse } from 'axios';
import { computed, defineComponent, ref, watch, watchEffect } from 'vue';
import { useStore } from 'vuex';
import { PageItem } from '../types';
import { debounce } from 'throttle-debounce';
import NavLink from '@/components/navigation/NavLink.vue';

export default defineComponent({
  components: {
    NavLink,
  },
  setup() {
    const store = useStore();
    const search = computed(() => store.state.searchstate);
    const width = computed(() => store.state.windowsize);
    const searchtext = ref('');
    const loading = ref(false);
    const searchfield = ref(null);
    const searchresult = ref<Array<PageItem>>([]);
    const bounce = debounce(500, () => {
      store
        .dispatch('searchPage', searchtext.value)
        .then((response: AxiosResponse) => {
          searchresult.value = response.data.message;
          loading.value = false;
        })
        .catch((error: AxiosError) => {
          console.error(error);
          loading.value = false;
        });
    });

    watchEffect(() => {
      // if (width.value > 992) {
      //   store.state.searchstate = true;
      // } else {
      //   store.state.searchstate = false;
      // }
      if (searchtext.value != '') {
        loading.value = true;
        bounce();
      }
    });

    const clearSearch = () => {
      searchtext.value = '';
    };

    const showOverlay = computed(() => {
      return searchtext.value != '';
    });

    return {
      search,
      searchtext,
      searchresult,
      showOverlay,
      loading,
      clearSearch,
    };
  },
});
